<template>
  <div
    v-if="!publisherComponent.hide"
    class="vehicle-listings-publisher-component-section"
  >
    <div
      v-show="!publisherComponent.hideHeader"
      class="vehicle-listings-publisher-component-section__header"
      @click="
        () => {
          if (
            windowWidth <= 769 &&
            publisherComponent.title != 'Recently Viewed'
          ) {
            publisherComponent.seeMoreAction();
            this.$store.commit('search/SET_LOADING');
            this.$router.push({ path: '/search' });
          }
        }
      "
    >
      <h5 class="text-weight-bolder q-ma-none">
        {{ publisherComponent.title }}
      </h5>
      <q-btn
        v-show="
          windowWidth > 769 &&
            publisherComponent.title != 'Recently Viewed' &&
            publisherComponent.title != 'Best Matches For You'
        "
        dense
        flat
        text-color="secondary"
        label="See More"
        @click="
          () => {
            publisherComponent.seeMoreAction();
            this.$store.commit('search/SET_LOADING');
            this.$router.push({ path: '/search' });
          }
        "
        class="header__action-button q-ml-md"
        icon-right="sym_r_arrow_right_alt"
      />
      <q-icon
        v-show="
          windowWidth <= 769 &&
            publisherComponent.title != 'Recently Viewed' &&
            publisherComponent.title != 'Best Matches For You'
        "
        name="sym_r_arrow_right_alt"
        color="secondary"
        size="24px"
        class="q-ml-sm"
        style="margin-bottom: -2px"
      />
    </div>
    <div style="margin-left: -16px; margin-top: -16px; margin-right: -16px">
      <component
        v-bind:is="publisherComponent.component"
        :key="publisherComponent.key + '_publisherComponentElement'"
        publisher-account-id="10001"
        component-placement="srp"
        component-theme="carscience"
        dark-mode
        horizontal-scrollable
        :infinite-scroll="true"
        :per-page="10"
        :channel-one="
          urlParams().get('utm_campaign') ? urlParams().get('utm_campaign') : ''
        "
        :options.prop="{
          location_search: {
            postal_code: $store.state.search.location.postalCode,
            radius: searchRadius,
            include_distance_field: true
          }
        }"
        @loaded-totals="e => checkIfHidePublisherComponent(e, i)"
        v-on="
          publisherComponent.component ==
          'jfpc-vehicle-listings-visitor-recently-clicked-make-model'
            ? { 'selected-make-model': initSelectedRecentlyViewedMakeModel }
            : {}
        "
        @vehicle-clicked="e => fireViewContentEvents(e.detail[0].bid_value)"
      ></component>
    </div>
  </div>
</template>

<script>
export default {
  name: "VehicleListingsPublisherComponentSection",
  props: {
    publisherComponentData: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    publisherComponent() {
      return this.publisherComponentData;
    },
    searchRadius() {
      let radius = 200;
      if (
        this.publisherComponent.component ==
        "jfpc-vehicle-listings-visitor-recently-clicked"
      ) {
        radius = 5000;
      } else if (
        this.publisherComponent.component ==
        "jfpc-vehicle-listings-visitor-recommended"
      ) {
        radius = 50;
      }
      return radius;
    }
  },
  methods: {
    checkIfHidePublisherComponent(event) {
      if (event.detail[0].results == 0) {
        this.publisherComponent.hide = true;
      } else {
        this.publisherComponent.hideHeader = false;
      }
    },
    initSelectedRecentlyViewedMakeModel(event) {
      this.publisherComponent.title =
        "Because You Viewed " +
        event.detail[0].make +
        " " +
        event.detail[0].model;
      this.publisherComponent.seeMoreAction = () => {
        this.$store.state.search.filter.models.model.push(
          event.detail[0].modelId
        );
        this.$store.commit("search/SET_FILTER_MODELS_ACTIVE");
      };
    }
  }
};
</script>

<style scoped lang="scss">
.vehicle-listings-publisher-component-section {
  .vehicle-listings-publisher-component-section__header {
    @include display-flex(row, flex-start, center);
    margin-bottom: $space-lg;
  }
}
</style>
